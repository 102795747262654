<template>
  <div>
    <v-modal-posts-insert v-model="dialogInsert"></v-modal-posts-insert>
    <v-modal-posts-delete v-model="dialogDelete" :id="selectedIndex"></v-modal-posts-delete>
    <!-- <v-modal-posts-update v-model="dialogUpdate" :id="selectedIndex"></v-modal-posts-update> -->



    <v-page-title title="Knowledge Base">
      <v-btn 
        elevation="0" 
        color="primary" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Post
      </v-btn>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-posts-breadcrumbs :post="post" :category_id="post.category_id" :category_name="post.category_name"></v-posts-breadcrumbs>
    </v-card>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-if="!post">
      <v-loader></v-loader> 
    </v-card>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-else>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <h2 class="mb-5">{{ post.title }}</h2>
              <h5 class="mb-0 grey--text" style="line-height: 1.5em;">
                <b>Posted By:</b> {{ post.user_name }}<br>
                Posted On: {{ $date.fullDateTime(post.created_at) }}
              </h5>
              <v-speed-dial
                v-model="authorButtons"
                direction="left"
                transition="scale-transition"
                absolute
                right
                top
                 v-if="user.super == 1 || user.id == post.user_id"
              >
                <template v-slot:activator>
                  <v-btn
                    v-model="authorButtons"
                    color="secondary"
                    dark
                    fab
                    small
                  >
                    <v-icon v-if="authorButtons">
                      mdi-close
                    </v-icon>
                    <v-icon small v-else>
                      mdi-wrench
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-if="user.super == 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="black--text">
        <div v-html="post.message" style="white-space: pre-wrap;max-height: 500px;overflow-y: auto;"></div>
      </v-card-text>
    </v-card>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-show="false">
      <v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <h2 class="mb-0">Comments</h2>
            </v-col>
            <v-col align="right">
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="black--text">
        <v-loader v-if="!posts"></v-loader>
        <v-list-item class="grow" v-for="(comment, index) in posts" :key="index">
          <v-list-item-avatar color="grey darken-3">
            <v-avatar color="primary" size="40">
              <v-gravatar :email="comment.user_email" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ comment.user_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $date.fullDateTime(comment.created_at) }}</v-list-item-subtitle>
            <div v-html="comment.message" style="white-space: pre-wrap;"></div>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Post.vue',
    data() {
      return {
        authorButtons: false,
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        post: {},
        posts:[],
        headers: [
          { text: "Title", value: "title", divider: true, align: 'left' },
          { text: "Author", value: "author", divider: true, align: 'left', width: '200px' },
          { text: "Created On", value: "created_at", divider: true, align: 'right', width: '250px' },
        ],
      }
    },
    async created(){
      this.selectedIndex = this.id;
      if(!this.$utilities.isEmpty(this.id)){
        await this.getPost();
        await this.getPosts();
      }else{
        this.$router.push({name: 'Knowledge Base'});
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      id(){
        return this.$route.params.id
      },
      isEditable(){
        const isAuthor = (this.post.user_id === this.user.id);
        const isWithinHour = (new Date() - new Date(this.post.created_at)) <= 3600000;
        return (isAuthor && isWithinHour) || this.user.super;
      }
    },
    methods: {
      async getPost(){
        this.post = await this.$store.dispatch('posts/get', {id: this.id});
      },
      async getPosts(){
        this.posts = await this.$store.dispatch('posts/parent', {parent_id: this.id});
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>